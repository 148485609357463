<template>
  <nav class="breadcrumb is-centered mt-5" aria-label="breadcrumbs">
    <ul>
      <li>
        <router-link to="/services/all">Home</router-link>
      </li>
      <li>
        <router-link to="/services">Services</router-link>
      </li>
      <li v-if="$route.params.service">
        <router-link :to="`/services/${$route.params.service}`">{{
          $route.params.service
        }}</router-link>
      </li>
    </ul>
  </nav>
  <router-view />
</template>

<script>
import http from "@/services/data";
export default {
  created() {
    http.connectSocket();
  },
};
</script>

<style>
h1 {
  text-align: center;
}
</style>
