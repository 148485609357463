<template>
  <div class="content">
    <h1 class="h1">Services</h1>

    <div class="column">
      <div class="column is-4 is-offset-4">
        <div class="box mt-5">
          <div class="field">
            <input
              id="filter"
              v-model="filter"
              type="text"
              placeholder="Filter"
              autofocus
              class="input"
            />
          </div>
          <ul v-if="services">
            <li v-for="service of filtered" :key="service">
              <router-link :to="`/services/${service}`">
                {{ service }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/services/data";
export default {
  data() {
    return {
      services: null,
      filter: "",
    };
  },

  computed: {
    filtered() {
      if (!this.filter.trim()) {
        return this.services;
      }

      return this.services.filter((x) => x.includes(this.filter));
    },
  },

  created() {
    this.getServices();
  },

  mounted() {
    const el = document.getElementById("filter");
    if (el) el.focus();
  },

  methods: {
    async getServices() {
      this.services = await http.getServices();
      this.services.unshift("all");
    },
  },
};
</script>

<style lang="css">
li {
  list-style: none;
}
ul {
  margin: 0;
  padding: 0;
}
</style>
