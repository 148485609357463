<template>
  <section class="section">
    <div class="columns">
      <div class="column is-2 is-offset-5">
        <div class="container">
          <div class="field">
            <div class="control">
              <input
                v-model="username"
                class="input"
                type="text"
                placeholder="Username"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <input
                v-model="password"
                class="input"
                type="password"
                placeholder="Password"
              />
            </div>
          </div>
        </div>

        <button @click="login" class="button is-fullwidth is-primary mt-3">
          Login
        </button>

        <p class="has-text-danger mt-4">{{ status }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import http from "@/services/data";
export default {
  data() {
    return {
      username: "",
      password: "",
      status: "",
    };
  },
  created() {},
  methods: {
    async login() {
      try {
        await http.login({ username: this.username, password: this.password });
        this.$router.push({ name: "Services" });
      } catch (error) {
        this.status = "Login failed, try again";
        this.password = "";
      }
    },
  },
};
</script>
