<template>
  <div class="content">
    <h1 class="h1">{{ $route.params.service }}</h1>

    <div class="box mt-5">
      <div class="columns">
        <div class="column">
          <p
            v-if="startDate || endDate"
            class="notification is-warning has-text-centered"
          >
            Auto update is paused (clear date to resume)
          </p>

          <p
            v-if="selectedId"
            class="notification is-warning has-text-centered"
          >
            Auto update is paused
            <a
              @click="
                selectedId = null;
                getService();
              "
              class="ml-2"
            >
              (clear)
            </a>
          </p>
        </div>
      </div>

      <div
        class="dropdown is-pulled-right"
        :class="{ 'is-active': isPageSizeDdlVisible }"
        @mouseenter="isPageSizeDdlVisible = true"
        @mouseleave="isPageSizeDdlVisible = false"
      >
        <div class="dropdown-trigger">
          <button
            class="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span>{{ pageSize }} items</span>
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>

        <div
          class="dropdown-menu"
          id="dropdown-menu"
          role="menu"
          @click="
            isPageSizeDdlVisible = false;
            getService();
          "
        >
          <div class="dropdown-content">
            <a
              @click="pageSize = 25"
              class="dropdown-item"
              :class="{ 'is-active': pageSize == 25 }"
            >
              25
            </a>
            <a
              @click="pageSize = 50"
              class="dropdown-item"
              :class="{ 'is-active': pageSize == 50 }"
            >
              50
            </a>
            <a
              @click="pageSize = 100"
              class="dropdown-item"
              :class="{ 'is-active': pageSize == 100 }"
            >
              100
            </a>
            <a
              @click="pageSize = 300"
              class="dropdown-item"
              :class="{ 'is-active': pageSize == 300 }"
            >
              300
            </a>
            <a
              @click="pageSize = 500"
              class="dropdown-item"
              :class="{ 'is-active': pageSize == 500 }"
            >
              500
            </a>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="control">
            <label class="radio">
              <input
                @click="
                  severity = null;
                  getService();
                "
                type="radio"
                name="all"
                :checked="!severity"
              />
              All
            </label>
            <label class="radio">
              <input
                @click="
                  severity = 1;
                  getService();
                "
                type="radio"
                name="error"
                :checked="severity === 1"
              />
              Error
            </label>
            <label class="radio">
              <input
                @click="
                  severity = -1;
                  getService();
                "
                type="radio"
                name="info"
                :checked="severity === -1"
              />
              Info
            </label>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="control">
            <label class="radio">
              <input
                @click="
                  country = null;
                  getService();
                "
                type="radio"
                name="allCountries"
                :checked="!country"
              />
              All
            </label>
            <label
              v-for="currentCountry of countries"
              :key="currentCountry"
              class="radio"
            >
              <input
                @click="
                  country = currentCountry;
                  getService();
                "
                type="radio"
                :name="country"
                :checked="country === currentCountry"
              />
              {{ currentCountry }}
            </label>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input
            @keyup.enter="getService()"
            v-model="search"
            class="input"
            type="text"
            placeholder="Search"
          />
        </div>
      </div>

      <div class="columns is-fullwidth">
        <div class="column is-6">
          <span>
            Start:
            <Calendar
              :initDate="startDate"
              @selected="
                startDate = $event;
                getService();
              "
            ></Calendar>
          </span>
          <span class="ml-6">
            End:
            <Calendar
              :initDate="endDate"
              @selected="
                endDate = $event;
                getService();
              "
            ></Calendar>
          </span>
        </div>
      </div>

      <div class="content">
        <div v-show="isLoading" class="has-text-centered">Loading...</div>
        <table
          v-show="!isLoading"
          class="table is-narrow is-bordered is-fullwidth"
        >
          <thead>
            <tr>
              <th v-if="$route.params.service === 'all'">Service</th>
              <th>Date</th>
              <th>Country</th>
              <th>Text</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="log of sortedData"
              :key="log"
              class="table-row"
              :id="log.id"
              :class="{
                'has-background-info': log.severity === -1,
                'has-background-danger': log.severity > 0,
                selected: selectedId && log.id == selectedId,
              }"
              @dblclick.prevent.stop="
                setId(log.id);
                setQueryParams({ selectedId }, true);
              "
            >
              <td
                v-if="$route.params.service === 'all'"
                class="has-text-white no-wrap"
              >
                <router-link
                  class="has-text-white is-underlined"
                  :to="`/services/${log.service}`"
                >
                  {{ log.service }}</router-link
                >
              </td>
              <td class="has-text-white date no-wrap">
                {{ formatDate(log.date) }}
              </td>
              <td class="has-text-white no-wrap">
                {{ log.country }}
              </td>
              <td class="has-text-white">
                {{ log.text }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/services/data";
import Calendar from "../components/Calendar.vue";
export default {
  components: { Calendar },
  data() {
    return {
      isPageSizeDdlVisible: false,
      isLoading: false,
      countries: ["SE", "NL", "DE", "DK", "FR"],
      data: [],
      severity: null,
      country: null,
      search: null,
      startDate: null,
      endDate: null,
      pageSize: 25,
      selectedId: null,
    };
  },

  computed: {
    eventsEnabled() {
      return !this.isLoading && !this.selectedId;
    },

    sortedData() {
      return [...this.data].sort((a, b) => b.date - a.date);
    },
  },

  created() {
    this.mapQueryData();

    this.getService();

    http.eventEmitter.on("log", this.addLog);
  },

  unmounted() {
    http.eventEmitter.removeAllListeners("log");
  },

  mounted() {
    if (this.selectedId) {
      const el = document.getElementById(this.selectedId);
      if (el) {
        el.scrollIntoView();
      }
    }
  },

  methods: {
    addLog(data) {
      const { service, text, severity, country = "" } = data;
      if (
        this.eventsEnabled &&
        (service === this.$route.params.service ||
          this.$route.params.service === "all")
      ) {
        if (this.severity && this.severity !== severity) return;
        if (
          this.search &&
          !text.toLowerCase().includes(this.search.toLowerCase())
        )
          return;
        if (this.startDate) return;
        if (this.endDate) return;
        if (
          this.country &&
          this.country.toLowerCase() !== country.toLowerCase()
        )
          return;

        this.data.unshift(data);
        if (this.data.length >= this.pageSize) this.data.pop();
      }
    },

    setId(id) {
      if (id == this.selectedId) {
        this.selectedId = null;
        this.getService();
        return;
      }

      this.selectedId = id;
    },

    formatDate(value) {
      if (!value) return value;

      const date = new Date(value);
      return `${date.toLocaleDateString("sv-SE")} ${date.toLocaleTimeString(
        "sv-SE"
      )}:${date.getMilliseconds()}`;
    },

    async getService() {
      const params = {
        severity: this.severity,
        search: this.search,
        startDate: this.startDate,
        endDate: this.endDate,
        country: this.country,
        pageSize: this.pageSize,
        selectedId: this.selectedId,
      };

      this.setQueryParams(params);

      this.isLoading = true;
      this.data = await http.getService(this.$route.params.service, params);
      this.isLoading = false;

      if (this.selectedId) {
        this.$nextTick(() => {
          const el = document.getElementById(this.selectedId);
          if (el) el.scrollIntoView();
        });
      }
    },

    setQueryParams(params, merge) {
      if (merge) params = { ...this.$route.query, ...params };

      this.$router.push({ query: params });
    },

    mapQueryData() {
      for (const key of Object.keys(this.$route.query)) {
        this[key] = this.$route.query[key];
      }
    },
  },
};
</script>

<style>
table {
  text-overflow: ellipsis !important;
  overflow: auto !important;
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

.table-row {
  cursor: pointer;
}

.table-row.selected {
  border-color: cyan;
  border-style: solid;
}

.date {
  word-break: keep-all;
  min-width: 180px;
}

td.now.wrap {
  white-space: nowrap;
}
</style>
