const EventEmitter = require("events");
class CustomEmitter extends EventEmitter {}

const eventEmitter = new CustomEmitter();

import axios from "axios";
const { io } = require("socket.io-client");

const apiEndpoint = "/api";

let httpInstance = null;
let socketInstance = null;

function createInstance() {
  httpInstance = axios.create({
    baseURL: apiEndpoint,
    headers: { token: getToken() },
    withCredentials: true,
  });
}

function getToken() {
  return window.localStorage.getItem("token");
}

function connectSocket() {
  if (!getToken()) return;

  socketInstance = io("/", {
    auth: {
      token: getToken(),
    },
  });

  socketInstance.on("log", (data) => {
    eventEmitter.emit("log", data);
  });
}

function handleUnauth(error) {
  if (error.response && error.response.headers["session-id"]) {
    initLogin(error.response.headers["session-id"]);
    window.location.reload();
    return;
  }

  if (error.response && error.response.status === 401) {
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  } else throw error;
}

async function getService(service, params) {
  try {
    const res = await httpInstance.get("/services/" + service, {
      params,
    });
    return res.data;
  } catch (error) {
    handleUnauth(error);
  }
}

async function getServices() {
  try {
    const res = await httpInstance.get("/services");
    return res.data;
  } catch (error) {
    handleUnauth(error);
  }
}

async function login({ username, password }) {
  try {
    const res = await httpInstance.post("/auth", {
      username,
      password,
    });

    const token = res.data.token;
    initLogin(token);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function initLogin(token) {
  window.localStorage.setItem("token", token);

  connectSocket();
}

createInstance();

export default {
  apiEndpoint,
  login,
  getServices,
  getService,
  connectSocket,
  socketInstance,
  eventEmitter,
};
