<template>
  <input @change="handleChange" type="datetime-local" v-model="date" />

  <button
    class="delete is-inline ml-4 close"
    @click="
      date = null;
      handleChange(null);
    "
  ></button>
</template>

<script>
export default {
  emits: ["selected"],
  props: ["initDate"],

  data() {
    return {
      date: this.initDate
        ? new Date(this.initDate).toISOString().slice(0, 16)
        : null,
    };
  },

  methods: {
    handleChange(e) {
      if (!e || !e.target) {
        return this.$emit("selected", null);
      }

      this.$emit(
        "selected",
        new Date(e.target.value).toISOString().slice(0, 24)
      );
    },
  },
};
</script>

<style scoped>
.close {
  margin-top: 2px;
}
</style>
